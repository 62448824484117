body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -webkit-linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -o-linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -moz-linear-gradient(116deg, black 50%, lightgrey 50%); */
}

h3 {
  font-size: 1.5em;
}

@media (min-width: 1012px) {
  body { 
      font-size: 15px;
  }
}

@media (min-width: 700px) {
  @media (max-width: 1012px) {
    body { 
      font-size: 10px;
    }
  }
  @media (max-height: 675px) {
    body { 
      font-size: 10px;
    }
  }
}

@media (min-width: 700px) {
  @media (max-height: 500px) {
    body { 
      font-size: 6px;
    }
  } 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.intro-screen, .room-code-display-container, .message-screen {
    position: absolute;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column
}

.room-code-header-battle {
    height: 4rem;
    border: 1px solid whitesmoke;
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 80%;
    margin-left: 15%;
}

.room-code {
    color: whitesmoke;
    position: relative;
    z-index: 3;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}



.title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: bold;
    font-size: 5em;
    margin-top: .3em;
    line-height: 1em;
    color: whitesmoke;
}

.start-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 10em;
    height: 10%;
}

.start-btn-inner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.start-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 12em;
    height: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 25px; 
    margin: 2em;
    margin-bottom: 0;
    margin-top: .5em;
}

.room-code-display {
    border: 4px solid lightgrey;
    width: 6em;
    height: 2em;
    font-weight: bold;
    font-size: 5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2em;
}

.room-code-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 40em;
}

.room-code {
    margin-top: .7em;
    color: whitesmoke;
}

.waiting-room {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 5em;
    color: whitesmoke; 
}

.message-display {
    font-weight: bold;
    font-size: 5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2em;
    margin-bottom: 3em;
    color: whitesmoke;
}

.category-display {
    font-weight: bold;
    font-size: 5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3%;
    color: whitesmoke;
    line-height: 1em;
    margin: .5em;
}

.category-list-item-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.category-message {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2em;
    margin-bottom: 3em;
    font-size: 5em;
    color: whitesmoke;
    line-height: 1em;
}

.message-loading-spin {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: whitesmoke
}

.round-count {
    font-weight: bold;
    font-size: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 5%;
    color: whitesmoke;
}

.battler-track-battler-info {
    line-height: 1em;
}

.battler-track {
    width: 70%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left: 15%;
    color: white;
    margin-top: 5%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-height: 20em;
}

.battler-track-art {
    height: 20em;
    width: 20em;
    background-size: cover;
    border-radius: 150px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-animation:spin 10s linear infinite;
            animation:spin 10s linear infinite;
}

.record-center-conatiner {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

@-webkit-keyframes spin{
	from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}	
}

@keyframes spin{
	from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}	
}

.record-center {
    background-color: rgba(0, 0, 0, .8);
    height: 4em;
    width: 4em;
    border-radius: 100px;
}

.battler-track-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 2em;
    margin-left: 12em;
    background-color: rgba(119, 136, 153, .5);
    width: 30%;
    border-radius: 20px;
    overflow-y: scroll;
}

.game-round-info {
    background-color: rgba(119, 136, 153, .5);
    height: 15em;
    border-radius: 25px;
    max-width: 70em;
}

.game-round-info-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.round-winner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 2em;

}

.round-winner-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2em;
    color: whitesmoke;
}

.round-winner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 5em;
    -webkit-justify-content: center;
            justify-content: center;
}

.round-winner-track {
    height: 20em;
    width: 20em;
    background-color: grey;
}

.round-winner-battler {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
    
}

.round-winner-battler-image {
    background-color: blueviolet;
    height: 20em;
    width: 20em;
}

.aux-battle-champion {
    border: 3px solid red;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    height: 30em;
}

.background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    -webkit-filter: blur(2em) opacity(0.8) brightness(100%);
            filter: blur(2em) opacity(0.8) brightness(100%);
    position: absolute;
    background-color: lightslategray;
  }

  .track-progress-bar {
    height: 2em;
    width: 65%;
    border: 1px solid black;
    border-radius: 25px;

    margin-left: 17.5%;
    margin-top: 5em;
}
  .progress {
      background-color: black;
      height: 70%;
      margin: .3em;
      border-radius: 25px;
  }

.round-winner-username {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: whitesmoke
}

.login-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 10em;
    height: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 25px;
}

.login-btn-link {
    color: whitesmoke;
    margin-top: .6em;
    font-family: Circular,Helvetica,Arial,sans-serif;
    cursor: pointer;
}

.join-game-player-list-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.player-list {
    width: 50%;
    position: relative;
    margin-top: 3.5em;
}

.player-list-list {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.room-code-display-container {
    width: 50%;
    position: relative;
}

.vertical-separator {
    width: .1em;
    height: 80vh;
    margin-top: 10vh;
    border: 1px solid whitesmoke;
    position: relative;
}

.players-list-title {
    color: whitesmoke;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 5em;
    font-weight: bold;
    margin-bottom: 15%;
    
}

.player-list-item {
    margin-top: 1em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
    color: whitesmoke;
}

.score-board-page-container {
    
}

.score-board-header {

}

.lineSeparator {

}

.score-board-container {

}

.score-board {

}

.board-list-container {

}

.board-list {

}

.score-board-element-line {

}

.score-board-element-container {

}

.score-board-element-name {

}

.score-board-element-score {
    
}
.room-code-header {
    height: 4rem;
    border: 1px solid whitesmoke;
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 80%;
    margin-left: 15%;
}

.room-code {
    color: whitesmoke;
    position: relative;
    z-index: 3;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.-room-code-header {
    margin-top: 1em;
}
.join-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    color: whitesmoke
}

.join-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-top: 2em;
    font-size: 3em;
}

.logo-display {
    height: 6.5em;
    width: 20em;
    margin-top: 3em;
}

.logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.input-roomcode {
    height: 2em;
    width: 100%;
    font-size: 300%;
    border: none;
    border-radius: 25px;
    text-align: center;
}

.input-username {
    width: 20em;
    text-align: center;
    border-radius: 25px;
}

.join-username-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-top: 4em;
}

.join-username-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.join-roomcode-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-top: 3em;
}

.join-roomcode-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.join-roomcode-border {
    border: 1px solid grey;
    width: 80%;
    border-radius: 25px;
}

.join-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin-top: 7em;
}

.join-btn-link {
    color: whitesmoke;
    margin-top: .6em;
    font-family: Circular,Helvetica,Arial,sans-serif;
    cursor: pointer;
}

.join-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 10em;
    height: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 25px;
}
.message-generic {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 15em;
}

.message-container {
    position: relative;
    color: whitesmoke;
}

.message-loading-spinner {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 8em;
}

.background {
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(100%);
    position: absolute; */
}

/* .app-background {
    background-image: none !important;
} */

.\--player-container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.\--player-album-art-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 6em;
}

.round-category {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2em;
    color: whitesmoke;
}

.\--player-artist {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: whitesmoke;
}

.song-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: whitesmoke;
    margin-top: 3em;
    font-style: italic;
}

.\--player-username {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: whitesmoke;
}

.round-winner-message {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3em;
    color: whitesmoke;
}

.lock-screen-icon {
    background-image: url(/static/media/locked.2bdd2f0c.png);
    height: 100em;
    width: 100em;
}

.category-input-page {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 50em;
    position: relative;
}

.category-input-container {
    background-color: rgb(189, 188, 188, 0.486);
    height: 10em;
    border-radius: 25px;
    margin: 2em;
}
.category-input-title {
    text-align: center;
    color: whitesmoke;
}
.category-input {
    margin-left: 1em;
    height: 90%;
    width: 85%;
    font-size: 1.5em;
    border: none;
}

.input-container {
    width: 90%;
    margin-left: 5%;
    border-radius: 25px;
    height: 3em;
    background-color: white;
}

.category-input-submit-btn {
    margin: .5em;
}
.category-input-skip-btn {
    margin: .5em;
}
.category-input-actions {
    margin-top: 1em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.start-screen {
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-top: 50%;
}

.start-screen-container {
    position: relative;
}
.settings-container {
    margin-top: 2em;
    position: relative;
    color: whitesmoke;
    overflow-y: scroll;
    max-height: 38em;
    margin-bottom: 2em;
}

.settings-radio-options-container {
    margin-left: 1em;
}

.settings-switch-options-container {
    margin-left: 1em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-right: 4em;
}

.settings-mode-selector {
    margin-left: 3.5em;
}

.settings-back-btn {
    margin-left: 1em;
}

.settings-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.category-list {
    list-style: none;
    overflow-y: scroll;
    max-height: 15em;
}

.remove-btn-container { 
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 2em;
    cursor: pointer;
    border-radius: 50px;
    height: 2em;
    margin-top: .4em;
    margin-right: 1em;
    background-color: rgba(211, 211, 211, 0.486);
}

.remove-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.inc-btn-container { 
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 2em;
    cursor: pointer;
    border-radius: 50px;
    height: 2em;
    margin-top: .4em;
    margin-right: 1em;
    background-color: rgba(211, 211, 211, 0.486);
    margin: calc(2rem - .14285714em) 0 1rem
}

.inc-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.dec-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.num-player-setting {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.category-input {
    color: whitesmoke;
    background-color: transparent;
    border: none;
    margin-left: 1em;
    width: 80%;
    font-size: 1.2em;
    font-weight: bold;
}

.category-list-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-right: 2em;
    margin-top: 1em;
    border: 1px solid grey;
    border-radius: 25px;
    width: 30em;
    height: 3em;
}

.settings-action-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2.5em;
    margin-right: 2em;
    margin-left: 3em;
}

.settings-divider {
    width: 80%;
}

.settings-categories-title {
    margin-left: 1em;
}

.slider {
    -webkit-appearance: none;
    height: 15px;
    border-radius: 5px;   
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    width: calc(100% - (1em));
  }
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #4CAF50;
    cursor: pointer;
}
  
.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.btn-choice-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.btn-choice {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}
.\--player-list {
    list-style: none;
    overflow-y: scroll;
    max-height: 25em;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.\--player-list-item-selected {
    background-color: rgba(102, 205, 170, .5);
    margin-top: 1em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
}

.\--player-list-item {
    display: -webkit-flex;
    display: flex;
    margin-top: .5em;
    margin-bottom: .5em;
}

.\--category-list-item-container {
    margin-top: 1.2em;
}

.\--category-list-item-container-selected {
    margin-top: 1.2em;
    background-color: rgba(102, 205, 170, .5);
}

.\--start-btn-container {
    border: 1px solid;
    width: 7em;
    height: 7em;
    border-radius: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.competitor-img-label {
    font-size: 2em;
    font-weight: bold;
}

.vs-label {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.vs-label-multiplayer {
    text-align: center;
}

.\--start-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 1em;
}

.player-name {
    font-size: 1.17em;
    font-weight: bold;
    text-align: center;
}

.player-avatar {
    height: 3em;
    width: 3em;
    border: 1px solid;
    border-radius: 30px;
    background: aliceblue;
}

.\--start-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 25px;
    cursor: pointer;
}

.\--settings-btn-container {

}

.nav-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    position: relative;
}

.nav-btn {
    width: 6em;
    height: 3em;
    border: 1px solid white;
    border-radius: 10px;
    background: #8434a5;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.nav-btn-text {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
}

.\--players-settings-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 25px;
    cursor: pointer;
}

.\--settings-btn-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 1em;
}

.\--players-list-container {
    margin: 3em;
    position: relative;
    color: whitesmoke;
    height: 35em;
}

.\--players-settings-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.\--player-select-instruction {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.\--player-album-art {
    height: 20em;
    width: 20em;
    background-size: cover;
}


.divider {
    width: 80%;
    margin-top: 2em;
}

.header-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 0;
    z-index: 2;
}

.action-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 1em;
}

.track-search-container {
    margin-left: 1em;
    margin-right: 1em;
    overflow-y: hidden;
    position: relative;
    color: whitesmoke;
}

.track-search-background {
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(100%);
    position: relative; */
}

.track-selection-render {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.search-bar {
    border: none;
    margin-left: .5em;
    margin-right: .5em;
    width: 85%;
    height: 100%;
    background: none;
    color: white;
}

.search-bar-container {
    border: 1px solid white;
    border-radius: 25px;
    height: 2em;
}

.search-icon {
    margin-left: .5em !important;
}

.result-list-container {
    margin-top: 2em;
}

.result-list {
    list-style: none;
    -webkit-margin-before: 0 !important;
            margin-block-start: 0 !important;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 450px;
    -webkit-margin-after: 1em !important;
            margin-block-end: 1em !important;
}

.result-track-explicit-marker {
    height: .3em;
    width: .3em;
    background-color: red;
    border-radius: 25px;
    margin-top: .5em;
}

.result-item {
    border: 1px solid grey;
}

.result-track-explicit {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.result-track-explicit-label {
    margin-left: .5em;
    font-weight: bold;
    font-size: .7em;
}

.result-artist-image {
    height: 4em;
    width: 4em;
    border-radius: 25px;
    margin-left: .6em;
    margin-top: .6em;
    margin-right: 2em;
}

.result {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.result-track-name-container {
    margin-top: 1em;
    margin-right: 1em;
}

.result-item-selected {
    background-color:rgba(102, 205, 170, .5);
}

.result-artist-name {

}

.result-track-name {
    margin-bottom: 0;
}

.successful-add {
    background-color: green;
    position: fixed;
    z-index: 3;
    width: 100%;
    color: whitesmoke;
    height: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    top: 0;
}

.success-message {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.divider-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.\--divider {
    margin-top: 1em;
    width: 20em;
}

.selection-display-image {
    height: 10em;
    width: 10em;
}

.short {
    height: 20em;
}

.details-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 80%;
    margin: 2em;
}

.play-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.selected-track-details {
    text-align: center;
}


@media screen and (min-width: 600px) {
    .divider-conditional {
        display: none;
    }

    .test-holder {
        display: -webkit-flex;
        display: flex;
    }

    .result-list-container {
        width: 50%;
    }

    .track-selection-render {
        width: 50%;
    }

    .short {
        height: auto;
    }

    .details-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 100%;
        margin: 5em;
    }

    body {
        font-size: 15px;
    }
}

.voting-round-category {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.vote-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.vote-container {
    position: relative;
    margin-top: 2em;
}

.vote-container-header {
    top: 0;
    width: 100%;
}

.voting-options-list {
    list-style: none;
    -webkit-margin-before: 0 !important;
            margin-block-start: 0 !important;
    -webkit-padding-start: 0 !important;
            padding-inline-start: 0 !important;
}

.divider {
    width: 80%;
    margin-top: 2em;
}

.option-keeper-username {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    
}

.option-trackTitle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 1.5em !important;
}

.album-art {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.voting-option-item-container-selected {
    background-color: rgba(102, 205, 170, .5);
}

.voting-options-list-container {
    margin-top: 2em;
    overflow-y: scroll;
    height: 35em;
}
.background {
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(100%);
    position: absolute; */
}

.app-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    position: absolute;
    /* background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(70%);
    position: absolute;
    background-color: grey; */
    overflow: hidden;
}

.\--background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    /* -webkit-filter: blur(2em) opacity(0.8) brightness(100%);
    filter: blur(2em) opacity(0.8) brightness(100%); */
    position: absolute;
    background-color: lightslategray;
}
