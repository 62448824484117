.settings-container {
    margin-top: 2em;
    position: relative;
    color: whitesmoke;
    overflow-y: scroll;
    max-height: 38em;
    margin-bottom: 2em;
}

.settings-radio-options-container {
    margin-left: 1em;
}

.settings-switch-options-container {
    margin-left: 1em;
    display: flex;
    justify-content: space-between;
    margin-right: 4em;
}

.settings-mode-selector {
    margin-left: 3.5em;
}

.settings-back-btn {
    margin-left: 1em;
}

.settings-title {
    display: flex;
    justify-content: center;
    width: 100%;
}

.category-list {
    list-style: none;
    overflow-y: scroll;
    max-height: 15em;
}

.remove-btn-container { 
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 2em;
    cursor: pointer;
    border-radius: 50px;
    height: 2em;
    margin-top: .4em;
    margin-right: 1em;
    background-color: rgba(211, 211, 211, 0.486);
}

.remove-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.inc-btn-container { 
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 2em;
    cursor: pointer;
    border-radius: 50px;
    height: 2em;
    margin-top: .4em;
    margin-right: 1em;
    background-color: rgba(211, 211, 211, 0.486);
    margin: calc(2rem - .14285714em) 0 1rem
}

.inc-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.dec-btn {
    font-size: 1.5em;
    font-weight: bold;
    margin-left: .45em;
    margin-bottom: .2em;
}

.num-player-setting {
    display: flex;
    justify-content: space-evenly;
}

.category-input {
    color: whitesmoke;
    background-color: transparent;
    border: none;
    margin-left: 1em;
    width: 80%;
    font-size: 1.2em;
    font-weight: bold;
}

.category-list-item {
    display: flex;
    justify-content: space-between;
    margin-right: 2em;
    margin-top: 1em;
    border: 1px solid grey;
    border-radius: 25px;
    width: 30em;
    height: 3em;
}

.settings-action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 2.5em;
    margin-right: 2em;
    margin-left: 3em;
}

.settings-divider {
    width: 80%;
}

.settings-categories-title {
    margin-left: 1em;
}

.slider {
    -webkit-appearance: none;
    height: 15px;
    border-radius: 5px;   
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    width: calc(100% - (1em));
  }
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #4CAF50;
    cursor: pointer;
}
  
.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.btn-choice-container {
    display: flex;
    justify-content: space-evenly;
}

.btn-choice {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}