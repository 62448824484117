.--player-list {
    list-style: none;
    overflow-y: scroll;
    max-height: 25em;
    padding-inline-start: 0;
}

.--player-list-item-selected {
    background-color: rgba(102, 205, 170, .5);
    margin-top: 1em;
    display: flex;
    justify-content: center;
    width: 80%;
}

.--player-list-item {
    display: flex;
    margin-top: .5em;
    margin-bottom: .5em;
}

.--category-list-item-container {
    margin-top: 1.2em;
}

.--category-list-item-container-selected {
    margin-top: 1.2em;
    background-color: rgba(102, 205, 170, .5);
}

.--start-btn-container {
    border: 1px solid;
    width: 7em;
    height: 7em;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.competitor-img-label {
    font-size: 2em;
    font-weight: bold;
}

.vs-label {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.vs-label-multiplayer {
    text-align: center;
}

.--start-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1em;
}

.player-name {
    font-size: 1.17em;
    font-weight: bold;
    text-align: center;
}

.player-avatar {
    height: 3em;
    width: 3em;
    border: 1px solid;
    border-radius: 30px;
    background: aliceblue;
}

.--start-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    cursor: pointer;
}

.--settings-btn-container {

}

.nav-buttons {
    display: flex;
    justify-content: space-around;
    position: relative;
}

.nav-btn {
    width: 6em;
    height: 3em;
    border: 1px solid white;
    border-radius: 10px;
    background: #8434a5;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.nav-btn-text {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
}

.--players-settings-btn {
    background-color: rgba(211, 211, 211, 0.486);
    width: 7em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    cursor: pointer;
}

.--settings-btn-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1em;
}

.--players-list-container {
    margin: 3em;
    position: relative;
    color: whitesmoke;
    height: 35em;
}

.--players-settings-btn-container {
    display: flex;
    justify-content: space-between;
}

.--player-select-instruction {
    display: flex;
    justify-content: center;
}

.--player-album-art {
    height: 20em;
    width: 20em;
    background-size: cover;
}