.background {
    /* left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(100%);
    position: absolute; */
}

.app-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    position: absolute;
    /* background-position: center center;
    filter: blur(2em) opacity(0.8) brightness(70%);
    position: absolute;
    background-color: grey; */
    overflow: hidden;
}

.--background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    /* -webkit-filter: blur(2em) opacity(0.8) brightness(100%);
    filter: blur(2em) opacity(0.8) brightness(100%); */
    position: absolute;
    background-color: lightslategray;
}