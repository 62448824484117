body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -webkit-linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -o-linear-gradient(116deg, black 50%, lightgrey 50%);
  background: -moz-linear-gradient(116deg, black 50%, lightgrey 50%); */
}

h3 {
  font-size: 1.5em;
}

@media (min-width: 1012px) {
  body { 
      font-size: 15px;
  }
}

@media (min-width: 700px) {
  @media (max-width: 1012px) {
    body { 
      font-size: 10px;
    }
  }
  @media (max-height: 675px) {
    body { 
      font-size: 10px;
    }
  }
}

@media (min-width: 700px) {
  @media (max-height: 500px) {
    body { 
      font-size: 6px;
    }
  } 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
